import React from 'react'
import { graphql } from 'gatsby'
import { PageLayoutEvents } from '../components/layout'
import { LocationTemplate } from '../components/locations'
import { MetaTagsMustHave, MetaTagsLocation, SchemaLocalBusiness } from '../components/seo'

const Location = ({ data, location }) => {
  const { location: locationNode } = data.wp
  const { seo } = locationNode

  return (
    <>
      <MetaTagsMustHave {...seo} />
      <MetaTagsLocation
        {...locationNode}
        {...seo}
      />
      <SchemaLocalBusiness
        {...locationNode}
        {...seo}
        url={location.pathname}
        title={locationNode.title}
      />
      <PageLayoutEvents>
        <LocationTemplate {...locationNode} />
      </PageLayoutEvents>
    </>
  )
}

export default Location

export const pageQuery = graphql`
  query LocationById($uri: ID!) {
    wp {
      location(
        id: $uri
        idType: URI
      ) {
        ...LocationDetailsFields
        seo {
          ...SeoFields
        }
      }
    }
  }
`
