import React, { lazy } from 'react'
import {
  Box, Heading, Parsed, Placeholder,
} from '../ui'
import { Lazy } from '../content'
import { PostContent, parsePostContent } from '../posts'

const MapPosition = lazy(() => import('../ui/MapPosition'))

const LocationTemplate = ({
  title,
  content,
  town,
  address,
  lat,
  lng,
}) => (
  <Box as="article">
    <Parsed
      mb={4}
      as="h1"
      fontSize={[5, 6]}
      component={Heading}
    >
      {title}
    </Parsed>

    <PostContent>
      {parsePostContent(content)}
    </PostContent>

    <Lazy fallback={<Placeholder height={300} />}>
      <MapPosition
        my={4}
        mx={[-3, -3, 0]}
        lat={lat}
        lng={lng}
        title={title}
        address={(
          [town, address]
            .filter((v) => !!v)
            .join(', ')
        )}
      />
    </Lazy>
  </Box>
)

export default LocationTemplate
